import echarts from './echarts'
import { Skeleton } from 'vant'
import globalComponents from './globalComponents'

export default {
    install(Vue) {
        Vue.use(echarts)
        Vue.use(Skeleton)
        Vue.use(globalComponents)
    },
}

import * as api from '../../services/login'

const getDefaultState = () => {
    return {}
}

export const state = getDefaultState()

const getters = {}

const mutations = {}

const actions = {
    init() {
        window.history.replaceState(null, null, window.location.origin + window.location.pathname + window.location.hash)
    },
    login(_, payload) {
        return api.LOGIN({
            external_login_code: '',
            tenant_ship_id: '',
            ...payload,
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}

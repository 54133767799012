import api from './api'

const url = '/mystery_box'

export const VERIFY_LOGIN = () => api.get(`${ url }/user/verify_login`)

export const VERIFY_ENTRANCE_CODE = (entrance_code) => api.get(`${ url }/entrance_code/verify?entrance_code=${entrance_code}`)

export const GET_DEVICE = (device_key) => api.get(`${ url }/device/${ device_key }`)

export const GET_USER = (payload) => api.get(`${ url }/user_info`)

export const GET_RECEIVE_RECORDS = (payload) => api.get(`${ url }/receive_record`)

export const GET_CODE = (payload) => api.post(`${ url }/code`, payload)

export const REGISTER = (payload) => api.post(`${ url }/user/register`, payload)

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import plugins from './plugins'
import wx from "weixin-js-sdk";
import 'font-awesome/css/font-awesome.css'
import { Grid, GridItem, SubmitBar, Divider, NoticeBar, Card, List, Area  } from 'vant';

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

Vue.prototype.$wx = wx
Vue.config.productionTip = false
// 移动端适配
import 'lib-flexible/flexible.js'

Vue.use(plugins)
Vue.use(Antd)
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(SubmitBar);
Vue.use(Divider);
Vue.use(NoticeBar);
Vue.use(Card);
Vue.use(List);
Vue.use(Area);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')

<template>
    <div class="tag">
        <div
            class="tag-color"
            :style="{ background: tags[status].color }"
        />
        <div class="status">
            {{ tags[status].label }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AsTag',
        props: {
            status: {
                type: [String, Number],
                required: true
            },
        },
        data() {
            return {
                tags: {
                    1: {
                        color: '#52C41A',
                        label: '生效中',
                    },
                    2: {
                        color: 'rgba(0, 0, 0, 0.15)',
                        label: '已停用',
                    },
                    3: {
                        color: '#FAAD14',
                        label: '待审核',
                    },
                }
            }
        }
    }
</script>

<style
    scoped
    lang="scss"
>
    @import "src/design/common";

    .tag {
        display: flex;
        align-items: center;
    }

    .tag-color {
        width: 8px;
        height: 8px;
        margin-right: 8px;
        border-radius: 50%;
    }

    .status {
        height: 22px;
        font-size: 14px;
        font-family: $font-family;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
    }
</style>

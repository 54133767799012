import Vue from 'vue'
import * as api from '../../services/mysteryBox'

const state = {
    device: {},
    user: {},
    mysteryBoxCode: '',
    expireTime: '',
    total: 0,
    receiveRecords: [],
}

const getters = {}

const mutations = {
    SET_DEVICE: (state, payload) => {
        Vue.set(state, 'device', payload)
    },
    SET_USER: (state, payload) => {
        Vue.set(state, 'user', payload)
    },
    SET_MYSTERY_BOX_CODE: (state, payload) => {
        Vue.set(state, 'mysteryBoxCode', payload)
    },
    SET_EXPIRE_TIME: (state, payload) => {
        Vue.set(state, 'expireTime', payload)
    },
    SET_TOTAL: (state, payload) => {
        Vue.set(state, 'total', payload)
    },
    SET_RECEIVE_RECORDS: (state, payload) => {
        Vue.set(state, 'receiveRecords', payload)
    },
}

const actions = {
    verifyLogin({ commit }, payload) {
        return api.VERIFY_LOGIN(payload).then((res) => {

        })
    },
    verifyEntranceCode({ commit }, entranceCode) {
        return api.VERIFY_ENTRANCE_CODE(entranceCode).then((res) => {
            commit('SET_DEVICE', res)
        })
    },
    getDevice({ commit }, deviceKey) {
        return api.GET_DEVICE(deviceKey).then((res) => {
            commit('SET_DEVICE', res)
        })
    },
    getUserInfo({ commit }, payload) {
        return api.GET_USER(payload).then((res) => {
            commit('SET_USER', res)
        })
    },
    getReceiveRecords({ commit }, payload) {
        return api.GET_RECEIVE_RECORDS(payload).then((res) => {
            commit('SET_TOTAL', res.total)
            commit('SET_RECEIVE_RECORDS', res.list)
        })
    },
    getMysteryBoxCode({ commit }, payload) {
        return api.GET_CODE(payload).then((res) => {
            commit('SET_MYSTERY_BOX_CODE', res.mystery_box_code)
            commit('SET_EXPIRE_TIME', res.expire_time)
        })
    },
    register({ commit }, payload) {
        return api.REGISTER(payload)
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}

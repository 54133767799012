<template>
    <div class="nav">
        <div class="nav-bar">
            <div
                v-if="hasReturn"
                class="left"
            >
                <div
                    class="left-arrow"
                    @click="back"
                >
                    <img src="../assets/images/left.svg" />
                </div>
                <div class="title">
                    {{ title }}
                </div>
            </div>
            <div
                class="right"
                @click="goto"
            >
                <div class="home">
                    <img src="../assets/images/home.svg" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NavBar',
        props: {
            title: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                hasReturn: false,
            }
        },
        mounted() {
            if (window.history.length <= 1) {
                this.hasReturn = false
            } else {
                this.hasReturn = true
            }
        },
        methods: {
            back() {
                this.$router.go(-1)
            },
            goto() {
                this.$router.push('/workbench')
            },
        },
    }
</script>

<style
    lang="scss"
    scoped
>
    .nav {
        height: 48px;
    }

    .nav-bar {
        display: flex;
        justify-content: space-between;
        /*padding: 15px;*/
        box-sizing: border-box;
        vertical-align: middle;
        /*position: fixed;*/
        /*width: 100%;*/
        /*left: 0;*/
        /*top: 0;*/
    }

    .left, .right {
        display: flex;
        align-items: center;
    }

    .left-arrow, .home {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-right: 10px;

        img {
            width: 100%;
        }
    }

    .title {
        height: 33px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #55565A;
        line-height: 33px;
    }
</style>

import {
    Button,
    Cell,
    CellGroup,
    Col,
    CountDown,
    Divider,
    Empty,
    Field,
    Form,
    Icon,
    Image,
    List,
    Loading,
    NavBar,
    Overlay,
    Picker,
    Popup,
    Radio,
    RadioGroup,
    Row,
    Search,
    Skeleton,
    Tabbar,
    TabbarItem,
    Tag,
    Toast,
    PullRefresh,
    Dialog,
    Calendar,
    Circle
} from 'vant'

import AsTag from '../components/AsTag'
import LevelPicker from '../components/LevelPicker'
import AsNavBar from '../components/NavBar'
import VueQrCode from '@chenfengyuan/vue-qrcode'
import AsLoading from '../components/Loading'

Toast.setDefaultOptions({ duration: 3000 })
Toast.setDefaultOptions('loading', { forbidClick: true })

export default {
    install(Vue) {
        Vue.use(Button)
        Vue.use(Cell)
        Vue.use(List)
        Vue.use(Tabbar)
        Vue.use(TabbarItem)
        Vue.use(CellGroup)
        Vue.use(Field)
        Vue.use(Form)
        Vue.use(Loading)
        Vue.use(CountDown)
        Vue.use(Toast)
        Vue.use(Row)
        Vue.use(Col)
        Vue.use(Image)
        Vue.use(Icon)
        Vue.use(NavBar)
        Vue.use(Search)
        Vue.use(Radio)
        Vue.use(RadioGroup)
        Vue.use(Skeleton)
        Vue.use(Tag)
        Vue.use(Popup)
        Vue.use(Divider)
        Vue.use(Picker)
        Vue.use(Overlay)
        Vue.use(Empty)
        Vue.component(AsTag.name, AsTag)
        Vue.component(LevelPicker.name, LevelPicker)
        Vue.component(AsNavBar.name, AsNavBar)
        Vue.component(VueQrCode.name, VueQrCode)
        Vue.component(AsLoading.name, AsLoading)
        Vue.use(PullRefresh)
        Vue.use(Dialog)
        Vue.use(Calendar)
        Vue.use(Circle)
        Vue.prototype.$toast = Toast
    },
}

<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import wx from 'weixin-js-sdk'
    export default {
        data() {
            return {
                loading: false
            }
        },
        computed: {
        },
        mounted() {

        },
        methods:{

        }
    }
</script>

<style lang="scss">
    html, body, #app {
        height: 100%;
        overflow: scroll;
    }
</style>

import axios from 'axios'
import router from '../router'
const baseURL = process.env.VUE_APP_API_URL

const instance = axios.create({ baseURL })

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

// instance.defaults.withCredentials = true

instance.interceptors.request.use(config => {
    return config
}, error => {
    return Promise.reject(error)
})

instance.interceptors.response.use(res => {
    return res.data
}, error => {
    const { status, data } = error.response
    if (status === 401) {
        window.location.href = `${ baseURL }/wechat/authorization?redirect_uri=${ encodeURIComponent(location.href) }`
    } else if (status === 403 || status === 404) {
        return router.replace({ path : '/404', query: data })
    } else if (status === 402) {
        return router.replace('/tenant')
    }
    return Promise.reject(error.response.data)
})

export default instance

import Vue from 'vue'
import * as api from '../../services/captcha'

const state = {
}

const getters = {
}

const mutations = {
    reset(state) {
        Vue.set(state, 'data', {})
    },
}

const actions = {
    image() {
        return api.GET()
    },
    sms(_, mobile) {
        return api.SEND_SMS(mobile)
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}

import api from './api'

const url = '/wechat'

export const GET = (app_id, current_url) =>
    api.get(`${ url }/${ app_id }/jssdk`, {
        params: {
            url: current_url
        }
    })

import Vue from 'vue'
import * as api from '../../services/wechat'

const state = {
    isLogin: {},
}

const getters = {}

const mutations = {
    SET_LOGIN_STATUS: (state, payload) => {
        Vue.set(state, 'isLogin', payload)
    },
}

const actions = {
    verifyLogin({ commit }, payload) {
        let {app_id, scope} = payload
        if( !scope ) {
            scope = 'snsapi_base'
        }
        return api.VERIFY(app_id, scope).then((res) => {
            commit('SET_LOGIN_STATUS', true)
        })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}

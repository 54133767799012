<template>
    <van-overlay
        :show="loading"
    >
        <van-loading
            class="loading"
            size="50"
            vertical
        >
            加载中...
        </van-loading>
    </van-overlay>
</template>

<script>
    export default {
        name: 'Loading',
        props: {
            loading: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style
    scoped
>
    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: white;
    }
</style>

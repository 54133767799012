<template>
    <van-popup
        v-model="showPicker"
        round
        position="bottom"
    >
        <van-picker
            ref="picker"
            show-toolbar
            :columns="list"
            @cancel="onCancel"
            @confirm="onConfirm"
        >
            <div slot="columns-bottom">
                <div style="color: #969799 !important;text-align: center;margin: 20px;">
                    生效状态的用户最多为{{ Number(employeeCount) }}人
                </div>
            </div>
        </van-picker>
    </van-popup>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'

    export default {
        name: 'LevelPicker',
        data() {
            return {
                showPicker: false,
            }
        },
        computed: {
            ...mapGetters('level', ['list']),
            ...mapGetters('clerks', {
                userList: 'list'
            }),
            ...mapState('clerks', ['employeeCount'])
        },
        methods: {
            onConfirm(value) {
                this.$emit('select', value)
                this.onCancel()
            },
            onCancel() {
                this.showPicker = false
            },
            showPopup() {
                this.showPicker = true
            },
            handleLeft() {
                this.$refs.picker.cancel()
            },
            handleRight() {
                this.$refs.picker.confirm()
            }
        },
    }
</script>

<style>
    .van-picker__toolbar{
        height: 100px;
    }
</style>

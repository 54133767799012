import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/404',
    },
    {
        path: '/mystery-box',
        name: 'MysteryBox',
        component: () => import('@/pages/MysteryBox/index'),
        meta: {
            title: '钻石世家',
        },
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/components/404'),
    },
    {
        path: '/confirm',
        name: 'Confirm',
        component: () => import('@/pages/Confirm'),
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

// router.beforeEach((to, from, next) => {
//     next()
// })

router.afterEach((to) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
})

export default router

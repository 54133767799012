import Vue from 'vue'
import * as api from '../../services/jssdk'

const state = {
    jsSdkConfig: [],
    shareLogo: ''
}

const getters = {}

const mutations = {
    SET_JS_CONFIG(state, payload) {
        Vue.set(state, 'jsSdkConfig', payload)
    }
}

const actions = {
    getJsSdk({ commit }, payload) {
        const {app_id, url} = payload
        return api.GET(app_id, url).then((res) => {
            commit('SET_JS_CONFIG', res)
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
